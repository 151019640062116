import fetch from "auth/FetchInterceptor";

const adindexService = {};

adindexService.getCredits = function (data, arraybuffer = false) {
	return fetch({
		url: "/publisher/credits",
		method: "post",
		data: data,
		responseType: arraybuffer ? "arraybuffer" : "basic",
	});
};

adindexService.getShowcase = function (data) {
	return fetch({
		url: "/publisher/showcase",
		method: "post",
		data: data,
	});
};

adindexService.getReportCommissions = function (data) {
	return fetch({
		url: "/publisher/report/commissions",
		method: "post",
		data: data,
	});
};

adindexService.getReportClickIns = function (data) {
	return fetch({
		url: "/publisher/report/clickIns",
		method: "post",
		data: data,
	});
};
adindexService.getReportClickOuts = function (data) {
	return fetch({
		url: "/publisher/report/clickOuts",
		method: "post",
		data: data,
	});
};
adindexService.getReportAdvertiser = function (data) {
	return fetch({
		url: "/publisher/report/advertiser",
		method: "post",
		data: data,
	});
};
adindexService.getCurrencyExchangeRate = function (data) {
	return fetch({
		url: "/publisher/report/currencyExchangeRate",
		method: "post",
		data: data,
	});
};

adindexService.getAdvertisers = function (data) {
	return fetch({
		url: "/publisher/advertisers",
		method: "post",
		data: data,
	});
};

adindexService.getProducts = function (data) {
	return fetch({
		url: "/publisher/product",
		method: "post",
		data: data,
	});
};

adindexService.getPublisherAdspaces = function (data) {
	return fetch({
		url: "/publisher/publisher/adspaces",
		method: "post",
		data: data,
	});
};

adindexService.getPublisherEmailVerification = function (data) {
	return fetch({
		url: "/publisher/publisher/emailVerification",
		method: "post",
		data: data,
	});
};
adindexService.resendEmailVerification = function (data) {
	return fetch({
		url: "/publisher/publisher/resendEmailVerification",
		method: "post",
		data: data,
	});
};

adindexService.getPublisherInformation = function (data) {
	return fetch({
		url: "/publisher/publisher/information",
		method: "post",
		data: data,
	});
};
adindexService.getPublisherBilling = function (data) {
	return fetch({
		url: "/publisher/publisher/information/billing",
		method: "post",
		data: data,
	});
};
adindexService.getPublisherApiToken = function (data) {
	return fetch({
		url: "/publisher/publisher/information/api",
		method: "post",
		data: data,
	});
};
adindexService.getPublisherCampaign = function (data) {
	return fetch({
		url: "/publisher/publisher/information/campaign",
		method: "post",
		data: data,
	});
};
adindexService.setPublisherInformation = function (data) {
	return fetch({
		url: "/publisher/publisher/information",
		method: "put",
		data: data,
	});
};
adindexService.setPublisherPassword = function (data) {
	return fetch({
		url: "/publisher/publisher/information/changePassword",
		method: "put",
		data: data,
	});
};
adindexService.setPublisherBilling = function (data) {
	return fetch({
		url: "/publisher/publisher/information/billing",
		method: "put",
		data: data,
	});
};
adindexService.sendSupportContact = function (data) {
	return fetch({
		url: "/publisher/support/contact",
		method: "put",
		data: data,
	});
};

adindexService.setAdspace = function (data) {
	return fetch({
		url: "/publisher/publisher/adspaces",
		method: "put",
		data: data,
	});
};

adindexService.getPublisherNotifications = function (data) {
	return fetch({
		url: "/publisher/publisher/notifications",
		method: "post",
		data: data,
	});
};
adindexService.setPublisherNotifications = function (data) {
	return fetch({
		url: "/publisher/publisher/notifications",
		method: "put",
		data: data,
	});
};

adindexService.getCountries = function (data) {
	return fetch({
		url: "/publisher/countries",
		method: "post",
		data: data,
	});
};

export default adindexService;
