import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/home`}
					component={lazy(() => import(`./home`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/reports/commissions`}
					component={lazy(() => import(`./reports-commissions`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reports/clicks`}
					component={lazy(() => import(`./reports-clicks`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reports/performance`}
					component={lazy(() => import(`./reports-performance`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reports/advertiser`}
					component={lazy(() => import(`./reports-advertiser`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/advertiser`}
					component={lazy(() => import(`./advertiser`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/product`}
					component={lazy(() => import(`./product`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/credits`}
					component={lazy(() => import(`./credits`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/tools/smartlink`}
					component={lazy(() => import(`./tools-smartlink`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tools/api`}
					component={lazy(() => import(`./tools-api`))}
				/>

				<Route
					exact
					path={`${APP_PREFIX_PATH}/account`}
					component={lazy(() => import(`./account`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/account/adspaces`}
					component={lazy(() => import(`./account-adspaces`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/account/password`}
					component={lazy(() => import(`./account-password`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/account/billing`}
					component={lazy(() => import(`./account-billing`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/account/notifications`}
					component={lazy(() => import(`./account-notifications`))}
				/>

				<Route
					path={`${APP_PREFIX_PATH}/support/documentation`}
					component={lazy(() => {
						window.open("https://docs.adindex.com", "_blank");
					})}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/support/faq`}
					component={lazy(() => import(`./support-faq`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/support/contact`}
					component={lazy(() => import(`./support-contact`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/support/installation`}
					component={lazy(() => import(`./support-installation`))}
				/>

				<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
