import {
	HomeOutlined,
	LineChartOutlined,
	EuroCircleOutlined,
	LinkOutlined,
	ShopOutlined,
	AuditOutlined,
	ContactsOutlined,
	PicRightOutlined,
	UserOutlined,
	CreditCardOutlined,
	ApiOutlined,
	QuestionCircleOutlined,
	ThunderboltOutlined,
	QuestionOutlined,
	CustomerServiceOutlined,
	LockOutlined,
	ShoppingOutlined,
	ToolOutlined,
	BulbOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "sidenav.dashboard",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "reports",
		path: `${APP_PREFIX_PATH}/reports`,
		title: "sidenav.reports",
		icon: LineChartOutlined,
		breadcrumb: false,
		submenu: [
			{
				key: "reports-commissions",
				path: `${APP_PREFIX_PATH}/reports/commissions`,
				title: "sidenav.reports.commissions",
				icon: EuroCircleOutlined,
				breadcrumb: true,
				submenu: [],
			},
			// {
			// 	key: "reports-clicks",
			// 	path: `${APP_PREFIX_PATH}/reports/clicks`,
			// 	title: "sidenav.reports.clicks",
			// 	icon: LinkOutlined,
			// 	breadcrumb: true,
			// 	submenu: [],
			// },
			{
				key: "reports-performance",
				path: `${APP_PREFIX_PATH}/reports/performance`,
				title: "sidenav.reports.performance",
				icon: ThunderboltOutlined,
				breadcrumb: true,
				submenu: [],
			},
			{
				key: "reports-advertiser",
				path: `${APP_PREFIX_PATH}/reports/advertiser`,
				title: "sidenav.reports.advertiser",
				icon: ShopOutlined,
				breadcrumb: true,
				submenu: [],
			},
		],
	},
	{
		key: "credits",
		path: `${APP_PREFIX_PATH}/credits`,
		title: "sidenav.credits",
		icon: AuditOutlined,
		breadcrumb: true,
		submenu: [],
	},
	{
		key: "advertiser",
		path: `${APP_PREFIX_PATH}/advertiser`,
		title: "sidenav.advertiser",
		icon: ShopOutlined,
		breadcrumb: true,
		submenu: [],
	},
	{
		key: "product",
		path: `${APP_PREFIX_PATH}/product`,
		title: "sidenav.product",
		icon: ShoppingOutlined,
		breadcrumb: true,
		submenu: [],
	},
	{
		key: "tools",
		path: `${APP_PREFIX_PATH}/tools`,
		title: "sidenav.tools",
		icon: ToolOutlined,
		breadcrumb: true,
		submenu: [
			{
				key: "tools-smartlink",
				path: `${APP_PREFIX_PATH}/tools/smartlink`,
				title: "sidenav.tools.smartlink",
				icon: BulbOutlined,
				breadcrumb: true,
				submenu: [],
			},
			{
				key: "tools-api",
				path: `${APP_PREFIX_PATH}/tools/api`,
				title: "sidenav.tools.api",
				icon: ApiOutlined,
				breadcrumb: true,
				submenu: [],
			},
		],
	},
	{
		key: "account",
		path: `${APP_PREFIX_PATH}/account`,
		title: "sidenav.account",
		icon: ContactsOutlined,
		breadcrumb: true,
		submenu: [
			{
				key: "account",
				path: `${APP_PREFIX_PATH}/account`,
				title: "sidenav.account.information",
				icon: UserOutlined,
				breadcrumb: true,
				submenu: [],
			},
			{
				key: "account-adspaces",
				path: `${APP_PREFIX_PATH}/account/adspaces`,
				title: "sidenav.account.adspaces",
				icon: PicRightOutlined,
				breadcrumb: true,
				submenu: [],
			},
			{
				key: "account-password",
				path: `${APP_PREFIX_PATH}/account/password`,
				title: "sidenav.account.password",
				icon: LockOutlined,
				breadcrumb: true,
				submenu: [],
			},
			{
				key: "account-billing",
				path: `${APP_PREFIX_PATH}/account/billing`,
				title: "sidenav.account.billing",
				icon: CreditCardOutlined,
				breadcrumb: true,
				submenu: [],
			},
			// {
			//   key: 'account-notifications',
			//   path: `${APP_PREFIX_PATH}/account/notifications`,
			//   title: 'sidenav.account.notifications',
			//   icon: BellOutlined,
			//   breadcrumb: true,
			//   submenu: []
			// },
		],
	},
	{
		key: "support",
		path: `${APP_PREFIX_PATH}/support`,
		title: "sidenav.support",
		icon: QuestionCircleOutlined,
		breadcrumb: true,
		submenu: [
			// {
			//   key: 'support-documentation',
			//   path: `${APP_PREFIX_PATH}/support/documentation`,
			//   title: 'sidenav.support.documentation',
			//   icon: BookOutlined,
			//   breadcrumb: true,
			//   submenu: []
			// },
			{
				key: "support-faq",
				path: `${APP_PREFIX_PATH}/support/faq`,
				title: "sidenav.support.faq",
				icon: QuestionOutlined,
				breadcrumb: true,
				submenu: [],
			},
			{
				key: "support-contact",
				path: `${APP_PREFIX_PATH}/support/contact`,
				title: "sidenav.support.contact",
				icon: CustomerServiceOutlined,
				breadcrumb: true,
				submenu: [],
			},
		],
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
